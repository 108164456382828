var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex flex-column justify-center align-center fill-height py-8 overflow-hidden",
    },
    [
      _c(
        "v-card",
        { attrs: { width: "350" } },
        [
          _c(
            "v-card-title",
            { staticClass: "justify-center align-center nuvolos-header" },
            [
              _c("v-img", {
                staticClass: "py-5",
                attrs: {
                  "max-width": "200",
                  "max-height": "22",
                  src: require("@/assets/Nuvolos-transparent.svg"),
                },
              }),
            ],
            1
          ),
          _c(
            "h2",
            { staticClass: "secondary--text text-center text-h6 mt-5" },
            [_vm._v("Accepting invitation")]
          ),
          _c("v-card-text", [
            _vm._v(" This might take a few seconds. "),
            _c("br"),
            _vm._v(" Please bear with us until we're done. "),
          ]),
          _c(
            "v-card-text",
            { staticClass: "text-center pb-16" },
            [
              _vm.error
                ? _c("dashboard-error", { attrs: { msg: _vm.errorMsg } })
                : [
                    _c("v-progress-linear", {
                      attrs: {
                        stream: "",
                        "buffer-value": "0",
                        color: "primary",
                        rounded: "",
                        height: "6",
                      },
                      model: {
                        value: _vm.progress,
                        callback: function ($$v) {
                          _vm.progress = $$v
                        },
                        expression: "progress",
                      },
                    }),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }